const Urls = {
  'daily-reports-list': () => '/api/v1/shipment/daily-reports/',
  'daily-reports-detail': (id) => `/api/v1/shipment/daily-reports/${id}/`,

  'drivers-multiple-delete': () => '/api/v1/shipment/drivers/multiple_delete/',
  'drivers-list': () => '/api/v1/shipment/drivers/',
  'driver-goals-list': () => '/api/v1/shipment/driver-goals/',
  'drivers-detail': (id) => `/api/v1/shipment/drivers/${id}/`,
  'driver-goals-detail': (id) => `/api/v1/shipment/driver-goals/${id}/`,

  'customers-list': () => '/api/v1/shipment/customers/',

  'weekly-reports-list': () => '/api/v1/shipment/weekly-reports/',
  'weekly-terminals-list': () => '/api/v1/shipment/weekly-terminals/',
  'weekly-goals-list': () => '/api/v1/shipment/weekly-goals/',
  'weekly-goals-detail': (id) => `/api/v1/shipment/weekly-goals/${id}/`,
  'weekly-production-list': () => '/api/v1/shipment/weekly-production/',

  'moves-detail': (id) => `/api/v1/shipment/moves/${id}/`,
  'moves-list': () => '/api/v1/shipment/moves/',

  'invoices-list': () => '/api/v1/shipment/invoice/',
  'invoices-multiple-delete': () => '/api/v1/shipment/invoice/multiple_delete/',
  'invoices-detail': (id) => `/api/v1/shipment/invoice/${id}/`,
  'invoices-preview': () => '/api/v1/shipment/invoice/preview/',
  'invoices-get-filter-values-for-column': () => '/api/v1/shipment/invoice/get_filter_values_for_column/',
  'invoices-recalculate': () => '/api/v1/shipment/invoice/recalculate/',

  'settings-list': () => '/api/v1/shipment/settings/',

  'inbound-settings-list': () => '/api/v1/shipment/inbound-settings/',
  'outbound-settings-list': () => '/api/v1/shipment/outbound-settings/',
  'inbound-settings-detail': (id) => `/api/v1/shipment/inbound-settings/${id}/`,
  'outbound-settings-detail': (id) => `/api/v1/shipment/outbound-settings/${id}/`,
  'inbound-settings-get-available-orig': () => '/api/v1/shipment/inbound-settings/available-orig/',
  'outbound-settings-get-available-dlvr-to': () => '/api/v1/shipment/outbound-settings/available-dlvr-to/',
  'inbound-settings-get-rail-yards': () => '/api/v1/shipment/inbound-settings/rail-yards/',
  'outbound-settings-get-rail-yards': () => '/api/v1/shipment/outbound-settings/rail-yards/',

  'celery-get-task-status': () => '/api/v1/celery/get_task_status/',
  'import_jobs-detail': (id) => `/api/v1/import/import-jobs/${id}/`,
  'import_jobs-start': (id) => `/api/v1/import/import-jobs/${id}/start/`,
  'import_jobs-validate': (id) => `/api/v1/import/import-jobs/${id}/validate/`,
  'import_jobs-list': () => '/api/v1/import/import-jobs/',

  'shipments-delete': () => '/api/v1/shipment/shipments/delete/',
  'shipments-multiple-delete': () => '/api/v1/shipment/shipments/multiple_delete/',
  'shipments-list': () => '/api/v1/shipment/shipments/',
  'shipments-detail': (id) => `/api/v1/shipment/shipments/${id}/`,
  shipmentsList: () => '/api/v1/shipment/shipments/',
  'shipments-get-last-status-change': (id) => `/api/v1/shipment/shipments/${id}/get_last_status_change/`,

  'uprr-update-from-supplier': () => '/api/v1/external/uprr/update_from_supplier/',
  'bnsf-update-from-supplier': () => '/api/v1/external/bnsf/update_from_supplier/',
  'ns-update-ns-shipments': () => '/api/v1/external/ns/update_ns_shipments/',
  'ns-update-from-supplier': () => '/api/v1/external/ns/update_from_supplier/',
  'determine-start-update-from-supplier': () => '/api/v1/external/determine/start_update_from_supplier/',
  'determine-store-data-from-all-api': () => '/api/v1/external/determine/store_data_from_all_api/',
  'shipments-update-selected-shipments': () => '/api/v1/shipment/shipments/update_selected_shipments/',
  'receiver-send-data-to-ftp': () => '/api/v1/external/receiver/send-data-to-ftp/',
  'uprr-get-shipment-report': (id) => `/api/v1/external/uprr/${id}/get_shipment_report/`,
  'ns-get-shipment-report': (id) => `/api/v1/external/ns/${id}/get_shipment_report/`,
  'bnsf-get-shipment-report': (id) => `/api/v1/external/bnsf/${id}/get_shipment_report/`,
  'shipments-get-filter-values-for-column': () => '/api/v1/shipment/shipments/get_filter_values_for_column/',

  'layouts-reset-default': () => '/api/v1/users/layouts/reset_default/',
  layouts_detail: (id) => `/api/v1/users/layouts/${id}/`,
  layouts_activate: (id) => `/api/v1/users/layouts/${id}/activate/`,
  'layouts-list': () => '/api/v1/users/layouts/',

  'users-list': () => '/api/v1/users/users/',
  'users-detail': (id) => `/api/v1/users/users/${id}/`,
  rest_password_reset: () => '/api/v1/users/password/reset/',
  rest_registration: () => '/api/v1/users/register/',
}

module.exports = {
  Urls
}
