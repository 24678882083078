/**
 * The Driver type
 * @typedef {Object} Driver
 * @property {number} id - Driver id
 * @property {string} name - Driver's name
 * @property {number} order - Driver's ordering number
 * @property {string} duty_status - Driver's status
 * @property {string} employment - Driver's type of employment
 * @property {boolean} is_working - Does driver work
 * @property {string} shift - Driver's type of shift
 * @property {number} tractor - Driver's tractor number
 * @property {string} start_time - Time when driver starts his shift
 * @property {boolean} is_working_on_monday - Does driver work on Monday
 * @property {boolean} is_working_on_tuesday - Does driver work on Tuesday
 * @property {boolean} is_working_on_wednesday - Does driver work on Wednesday
 * @property {boolean} is_working_on_thursday - Does driver work on Thursday
 * @property {boolean} is_working_on_friday - Does driver work on Friday
 * @property {boolean} is_working_on_saturday - Does driver work on Saturday
 * @property {boolean} is_working_on_sunday - Does driver work on Sunday
 */

/**
 * The Daily Report type
 * @typedef {Object} DailyReport
 * @property {number} id - Daily Report id
 * @property {number} driver_weekly_report - Id of the driver
 * @property {string} start_time - Start time
 * @property {string} end_time - End time
 * @property {number} duty_status - Status of the driver and report
 * @property {number} shipments_count - Total count of the shipments
 * @property {number} bobtails_count - Total count of the bobtails
 * @property {number} total_moves_count - Count of the moves
 * @property {number} worked_hours_amount - Total hours worked
 * @property {Move[]} moves - Moves
 */

/**
 * The Move type
 * @typedef {Object} Move
 * @property {number} id - Move id
 * @property {number} daily_report - Daily report id
 * @property {string} shipment_from - Shipment from
 * @property {string} shipment_to - Shipment to
 * @property {string} trace - Trailer
 * @property {boolean} trailer_state - Trailer state
 * @property {string} customer - Customer name
 * @property {string} move_type - Move type
 * @property {number} order_number - Order number
 * @property {string} origin - Origin
 * @property {boolean} hazmat - Is hazmat
 * @property {string} note - Note
 */

/**
 * The Week type
 * @typedef {Object} Week
 * @property {number} id - Week id
 * @property {string} start_date - Start date of the week - Monday
 * @property {string} end_date - End date of the week - Sunday
 * @property {number} monday_moves_count - Count of the moves at Monday
 * @property {number} tuesday_moves_count - Count of the moves at Tuesday
 * @property {number} wednesday_moves_count - Count of the moves at Wednesday
 * @property {number} thursday_moves_count - Count of the moves at Thursday
 * @property {number} friday_moves_count - Count of the moves at Friday
 * @property {number} saturday_moves_count - Count of the moves at Saturday
 * @property {number} sunday_moves_count - Count of the moves at Sunday
 * @property {number} after_monday_moves_count - Count of the moves by Monday
 * @property {number} after_tuesday_moves_count - Count of the moves by Tuesday
 * @property {number} after_wednesday_moves_count - Count of the moves by Wednesday
 * @property {number} after_thursday_moves_count - Count of the moves by Thursday
 * @property {number} after_friday_moves_count - Count of the moves by Friday
 * @property {number} after_saturday_moves_count - Count of the moves by Saturday
 * @property {number} after_sunday_moves_count - Count of the moves by Sunday
 * @property {number} total_moves_count - Total count of the moves
 */

/**
 * The Weekly Production type
 * @typedef {Object} WeeklyProduction
 * @property {Week[]} weeks - Weeks of the period
 * @property {number} average_moves_count - Average moves count for the period
 * @property {number} total_moves_count - Todal moves count for the period
 */

/**
 * The Driver Goal type
 * @typedef {Object} DriverGoal
 * @property {number} id - Driver goal id
 * @property {string} name - Driver type name
 * @property {number} goal - Goal of the moves for the current driver type
 * @property {string} created - The datestring when the goal was created
 * @property {string} modified - The datestring when the goal was modified
 */

/**
 * The Week Dates
 * @typedef {Object} WeekDates
 * @property {string} startDate - Start date of the week - Monday
 * @property {string} endDate - End date of the week - Sunday
 */

/**
 * The Weekly Goal type
 * @typedef {Object} WeeklyGoal
 * @property {number} id - Weekly goal id
 * @property {string} day - Weekly goal weekday name
 * @property {number} goal - Goal of the moves for the current weekday
 * @property {string} created - The datestring when the goal was created
 * @property {string} modified - The datestring when the goal was modified
 */


exports.unused = {};
