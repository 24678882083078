const centralTimeOptions = {
  timeZone: 'US/Central',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const UTCTimeOptions = {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

/**
 * Get current date and time in 'US/Central' timezone.
 * @returns {string} Date and time in 'US/Central' timezone.
 */
function getCurrentDateString() {
  return new Date().toLocaleString('en-US', centralTimeOptions);
}

/**
 * Substract hours from date.
 * @param {number} numOfHours hours to substract
 * @param {Date} date initial date
 * @returns {string} formattedDate - date in string format for the backend
 */
function subtractHours(numOfHours, date) {
  const newDate = date;

  newDate.setHours(newDate.getHours() - numOfHours).toString();

  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const year = newDate.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

/**
 * Get date the in right format for backend - YYYY-MM-DD hh:mm
 * @param {string} dateString Date string in format: '05/05/2022, 05:00'
 * @returns {string} formattedDate - date in string format for the backend
 */
function getFormattedDate(dateString) {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hours= date.getHours();
  const minutes = date.getMinutes();
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

  return formattedDate;
}

/**
 * Get date the in right format for backend - YYYY-MM-DD hh:mm
 * @param {string} dateString Date string in format: '05/05/2022, 05:00'
 * @returns {string} formattedDate - date in string format for the backend
 */
function getFormattedDateWithoutTime(date) {
  if (!date) {
    return null;
  }

  const dateString = date.split(', ')[0];
  const [ month, day, year ] = dateString.split('/');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

/**
 * Get date in 'US/Central' timezone
 * @param {string} dateString Date string from server; example: '05/05/2022 05:00'
 */
function getCentralDate(dateString) {
  if (!dateString) {
    return null;
  }

  const [date, time] = dateString.split(' ');
  const [month, day, year] = date.split('/');
  const [hours, minutes] = time.split(':');
  const utsDate = `${year}-${month}-${day}T${hours}:${minutes}:00.00Z`;
  const centralDate = new Date(utsDate).toLocaleString('en-US', centralTimeOptions);

  return centralDate;
}

/**
 * Get date in 'UTC' timezone to send to the server
 * @param {string} dateString Date string from form; example: '2022-05-05 05:00'
 */
function getUTCDate(dateString) {
  if (!dateString) {
    return null;
  }

  const [date, time] = dateString.split(' ');
  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');
  const centralDate = `${month}/${day}/${year} ${hours}:${minutes}:00 UTC-0500`;
  const utcDate = new Date(centralDate).toLocaleString('en-US', UTCTimeOptions);

  return getFormattedDate(utcDate);
}

/**
 * Convert time from 'UTC' timezone from the server to 'Cental/US' timezone for displaying
 * @param {string} time Time string from the server; example: '06:00:00'
 * @returns {string} time Converted time; example: '01:00:00'
 */
function converTimeFromUtcToCentral(time) {
  if (!time) {
    return null;
  }

  const hoursDifference = 5;
  const [ hours, minutes, seconds ] = time.split(':');
  const date = new Date(new Date().setHours(hours));
  const newDate = new Date(new Date().setHours(date.getHours() - hoursDifference));
  const convertedHours = newDate.getHours();
  const convertedTime = `${convertedHours}:${minutes}:${seconds}`;

  return convertedTime;
}

/**
 * Convert time from 'Cental/US' timezone from the form to 'UTC' timezone for the server
 * @param {string} time Time string from the form; example: '01:00:00'
 * @returns {string} time Converted time; example: '06:00:00'
 */
function converTimeFromCentralToUtc(time) {
  if (!time) {
    return null;
  }

  const hoursDifference = 5;
  const [ hours, minutes, seconds ] = time.split(':');
  const date = new Date(new Date().setHours(hours));
  const newDate = new Date(new Date().setHours(date.getHours() + hoursDifference));
  const convertedHours = newDate.getHours();
  const convertedTime = `${convertedHours}:${minutes}:${seconds}`;

  return convertedTime;
}

/**
 * Convert date to datestring in format '30/10/2022'.
 * @param {Date} date Date object
 * @returns {string} datestring in format '30/10/2022'
 */
function getDateStringFromDate(date) {
  if (!date) {
    return null;
  }

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

/**
  * Get formatted date for export.
  * @param {string} date Selected date string in format 'MM/DD/YYYY'.
  * @returns {string} Date string in format 'MM-DD-YY'.
  */
function getFormattedDateForExport(date) {
  const [month, day, year] = date.split('/');
  const shortYear = year.slice(2);
  const formattedDate = `${month}-${day}-${shortYear}`;

  return formattedDate;
}

module.exports = {
  subtractHours,
  getCurrentDateString,
  getFormattedDate,
  getFormattedDateWithoutTime,
  getCentralDate,
  getUTCDate,
  converTimeFromUtcToCentral,
  converTimeFromCentralToUtc,
  getDateStringFromDate,
  weekdays,
  getFormattedDateForExport,
};
