const { getDrivers } = require('ui/js/shipments/api_calls');

const SEARCH_DRIVERS_LIMIT = 5;

/**
 * Search drivers
 * @param {Tabulator.CellComponent} cell
 * @param {string} filterTerm Search value for filter.
 */
async function searchDrivers(cell, filterTerm) {
  const currentDriver = cell.getData().driver_data;

  if (currentDriver !== null && filterTerm === '') {
    const currentValue = [{
      value: currentDriver.id,
      label: currentDriver.name,
    }];

    return Promise.resolve(currentValue);
  }

  const params = {
    search: filterTerm ?? '',
    limit: SEARCH_DRIVERS_LIMIT,
  };

  const drivers = await getDrivers(params);

  const formattedDrivers = drivers.results.map(driver => ({
    value: driver.id,
    label: driver.name,
  }));


  return formattedDrivers;
}


module.exports = {
  searchDrivers,
};
